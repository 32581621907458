import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArtworkCaptionLite from "../components/artwork-caption-lite"
import ArtworkGallery from "../components/artwork-gallery"
import AirtableRichtext from "../components/airtable-richtext"
import { smarten } from "../utils/utils"

const WEBSITE_LINK_TEXT = "Artist Website"

const ArtistTemplate = ({ data }) => {
  const {
    Name,
    Artwork,
    Featured_Art,
    Bio,
    Website,
    Artist_Type,
    Artist_Profile_Photo,
  } = data.airtable.data

  const featuredArt =
    Boolean(Featured_Art) &&
    Boolean(Featured_Art[0].data.Images) &&
    Boolean(Featured_Art[0].data.Images.localFiles)
      ? Featured_Art[0]
      : false

  const featuredArtIsLandscape =
    featuredArt.data &&
    featuredArt.data.Images.localFiles[0].childImageSharp.fluid.aspectRatio > 1

  return (
    <Layout>
      <SEO title={Name} description={Bio} />
      {featuredArt.data ? (
        <div
          className={classnames(
            "flex w-full items-center flex-col mb-5 md:mb-10",
            {
              "md:flex-row": !featuredArtIsLandscape,
              "max-w-5xl mx-auto": featuredArtIsLandscape,
            }
          )}
        >
          <div
            className={classnames("text-center", {
              "flex-1": !featuredArtIsLandscape,
            })}
          >
            <h1 className="md:text-5xl my-8">{Name}</h1>
          </div>
          <div
            className={classnames("p-4 w-full", {
              "flex-1": !featuredArtIsLandscape,
            })}
          >
            <Link to={`/${featuredArt.recordId}`}>
              <Img
                fluid={
                  featuredArt.data.Images.localFiles[0].childImageSharp.fluid
                }
                className="mb-4 max-h-80vh"
                imgStyle={{ objectFit: "contain" }}
              />
            </Link>
            <div className="text-center">
              <ArtworkCaptionLite artwork={featuredArt.data} />
            </div>
          </div>
        </div>
      ) : (
        <h1 className="md:text-5xl text-center">{Name}</h1>
      )}

      {!!Bio && (
        <div className="bg-white py-10">
          <div className="max-w-4xl mx-auto w-full px-4 mb-10 py-10 flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-56">
              {!!Artist_Type && <p>{Artist_Type}</p>}
              {Boolean(Artist_Profile_Photo) &&
                Boolean(Artist_Profile_Photo.localFiles) && (
                  <Img
                    className="w-full"
                    fluid={
                      Artist_Profile_Photo.localFiles[0].childImageSharp.fluid
                    }
                  />
                )}

              {!!Website && (
                <div className="mt-10">
                  <a href={Website} target="_blank" rel="noreferrer">
                    {WEBSITE_LINK_TEXT}
                  </a>
                </div>
              )}
            </div>
            {!!Bio && (
              <AirtableRichtext
                text={smarten(Bio)}
                className="mt-8 max-w-2xl md:text-lg md:pl-4"
              />
            )}
          </div>
        </div>
      )}

      <ArtworkGallery artwork={Artwork} />
    </Layout>
  )
}

export default ArtistTemplate

export const query = graphql`
  query ArtistByName($name: String!) {
    airtable(table: { eq: "Artists" }, data: { Name: { eq: $name } }) {
      data {
        Name
        Artist_Type
        Bio
        Website
        Artwork {
          recordId
          data {
            Name
            Images {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            Unit_Price
            Web_Description
            Artwork_Type
            Height
            Width
          }
        }
        Featured_Art {
          recordId
          data {
            Name
            Images {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                  }
                }
              }
            }
            Year
            Web_Description
            Artwork_Type
          }
        }
        Artist_Profile_Photo {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
